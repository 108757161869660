import {POST_TARIF, POST_TARIF_RELOAD, setTarif, TARIF, TARIF_RELOAD} from '../../../actions/app/tarif/tarif.actions'
import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setErrorTarif, setLoader} from '../../../actions/app/ui/ui.actions'
import {setNotification} from "../../../actions/core/notifications/notifications.actions";
import {FILL} from "../../../actions/app/fill/fill.actions";
import { formatDataForApi } from "../fill/formatDataForApi";
import {setDemandeTarifAfterGetTarif} from "../../../actions/app/initSession/initSession.actions";
import {analyticsSetEvent} from "../../../actions/core/analytics/analytics.actions.js";

export const tarifMiddleware = () => next => action => {
    next(action)

    const { payload } = action
    let nextActions;

    switch (action.type){

        case POST_TARIF:
            let body = {}

            if (payload.body){
                body = formatDataForApi(payload.body)
            }

            next([
                apiRequest({
                    body,
                    method: 'POST',
                    url: payload.data,
                    entity: TARIF,
                    otherData: {
                        ...payload.meta.otherData,
                        codePromoValue: payload.body?.DemandeTarif?.CodePromo?.CodePromo
                    },
                }),
                setLoader({state: true, entity: TARIF})
            ]);
            break;

        case `${TARIF} ${API_SUCCESS}`:
            nextActions = [
                setTarif({data: payload.data.data.Tarif}),
                setDemandeTarifAfterGetTarif({data: payload.data.data.DemandeTarif}),
                setError({state: false, entity: TARIF}),
                setLoader({state: false, entity: TARIF})
            ];

            if(payload?.meta?.otherData?.codePromo && payload?.meta?.otherData?.codePromoValue){
                nextActions.push(analyticsSetEvent({event: 'promoAttempt', ap1: payload?.meta?.otherData?.ap1, datas: {
                    'promoAttempt': {
                        'promoCode' : action.payload.meta.otherData.codePromoValue, // à faire
                        'status' : payload.data.data.Tarif.CodePromo.Eligible ? 'Réussite' : 'Echec',
                    }
                }}));
            }
            next(nextActions);
            break;

        case `${TARIF} ${API_ERROR}`:
            next([
                analyticsSetEvent({event: 'errorMessage', ap1: payload?.meta?.otherData?.ap1, datas: {
                    'errorMessage': {
                        'label': payload?.data?.response?.data?.error || '',
                    }
                }}),
                setNotification({entity: FILL, title: 'Attention', confirmButtonText: 'Continuer', icon: 'error', text: payload.data.response.data.error}),
                setErrorTarif({data: payload.data.response.data.error}),
                setError({state: true, entity: TARIF}),
                setLoader({state: false, entity: TARIF})
            ]);
            break;

        case POST_TARIF_RELOAD:
            next([
                apiRequest({body: payload.body, method: 'POST', url: payload.data, entity: TARIF_RELOAD}),
                setLoader({state: true, entity: TARIF_RELOAD})
            ]);
            break;

        case `${TARIF_RELOAD} ${API_SUCCESS}`:
            next([
                setTarif({data: payload.data.data.Tarif}),
                setError({state: false, entity: TARIF_RELOAD}),
                setLoader({state: false, entity: TARIF_RELOAD})
            ]);
            break;

        case `${TARIF_RELOAD} ${API_ERROR}`:
            next([
                setNotification({entity: FILL, title: 'Attention', confirmButtonText: 'Continuer', icon: 'error', text: payload.data.response.data.error}),
                setErrorTarif({data: payload.data.response.data.error}),
                setError({state: true, entity: TARIF_RELOAD}),
                setLoader({state: false, entity: TARIF_RELOAD})
            ]);
            break;

        default:
            return null
    }
}
